import React from 'react';

import './authcard.css';

export default class AuthCard extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
		};

		this.handleUsernameChange = this.handleUsernameChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleAuthSubmit = this.handleAuthSubmit.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.error && this.props.error)
			this.setState({
				progress: false
			});
	}

	handleUsernameChange(event) {
		this.setState({
			username: event.target.value
		});
	}

	handlePasswordChange(event) {
		this.setState({
			password: event.target.value
		});
	}

	handleAuthSubmit(event) {
		event.preventDefault();
		const username = this.state.username;
		const password = this.state.password;
		this.setState({
			password: '',
			progress: true,
		});
        if (this.props.handleSignIn) this.props.handleSignIn(username, password);
        else this.setState({progress: false});
	}

	render() {
		return (
			<div class='authcard'>
				<p class='authcard-tooltip'>{this.props.tooltip}</p>
				<p hidden={!this.props.error} class='authcard-error'>{this.props.error}</p>
				<p hidden={!this.state.progress} class='authcard-progress'>Signing in...</p>
				<form class='authcard-credentials' onSubmit={this.handleAuthSubmit}>
					<input disabled={this.state.progress} class='authcard-username' placeholder={this.props.usernamePlaceholder} value={this.state.username} onChange={this.handleUsernameChange} />
					<input disabled={this.state.progress} class='authcard-password' placeholder={this.props.passwordPlaceholder} type='password' value={this.state.password} onChange={this.handlePasswordChange} />
					<input disabled={this.state.progress || !this.state.username || !this.state.password} class='authcard-button' type='submit' value='Sign In' />
				</form>
			</div>
		);
	}

}