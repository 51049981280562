import React from 'react';
import {Link} from 'react-router-dom';

import './footer.css';

export default class Footer extends React.Component {

	render() {
		return (
			<div class='footer'>
				<div class='footer-section'>
					<p class='footer-section-title'>Ceremony</p>
					<p class='footer-section-time'>4:00 PM EST</p>
					<p class='footer-section-date'>8/29/2020</p>
					<Link class='footer-section-watch' to='/watch'>Watch Event</Link>
				</div>
				<div class='footer-section'>
					<p class='footer-section-title'>Reception</p>
					<p class='footer-section-time'>7:00 PM EST</p>
					<p class='footer-section-date'>8/29/2020</p>
					<Link class='footer-section-watch' to='/watch'>Join Event</Link>
				</div>
			</div>
		);
	}

}