import React from 'react'

// import Header from '../components/header.js';
// import CoverPhoto from '../components/coverphoto.js';
// import Footer from '../components/footer.js';
import Page from '../componentsv2/page.js';
import CoverPhoto from '../componentsv2/webparts/coverphoto.js';
import Article from '../componentsv2/webparts/article.js';
import FlexRow from '../componentsv2/webparts/flexrow.js';
import Card from '../componentsv2/webparts/card.js';

// import '../style/pages/home.css';

export default class HomePage extends React.Component {

// rightImage='/images/gallery/thumbnails/1.jpg' 

	render() {

		const paragraphsOne = [
			'Hello, David and Lianne here! Thanks for visiting this page. We got married on August 29th, 2020!',
            'We met at the Cambridge Hall of Jehovah’s Witnesses. After months of becoming best friends, we started dating and three years later we’re getting married!',
            'We got engaged at our favorite spot, the Monterey Bay Aquarium, in California. It was a beautiful day we will remember forever!', 
		];
        
        const paragraphsOneLeft = [
            'We met at the Cambridge Hall of Jehovah’s Witnesses. After months of becoming best friends, we started dating and three years later we’re getting married!', 
        ];
        
        const paragraphsOneRight = [
            'We got engaged at our favorite spot, the Monterey Bay Aquarium, in California. It was a beautiful day we will remember forever!', 
        ];
        
        const paragraphsTwo = [
            'Fun facts!',
            'We enjoy painting, playing tennis, and singing Taylor Swift together.',
            'Our goals for the future together are to start a family and continue laughing the night away!',
            'David made this whole website from scratch!',
        ];
        
        const paragraphsThree = [
            'Who are Jehovah’s Witnesses? At jw.org you can find out more about our wedding traditions along with everyday practical information for families, couples, and individuals! :)'
        ];

		return (
			<Page home>
				<CoverPhoto src='/images/cover.jpg' />
				<Article leftImage={'/images/articles/homeleft.jpg'} rightImage={'/images/articles/homeright.jpg'} paragraphs={paragraphsOne} />
                <Article paragraphs={paragraphsTwo} />
                <Article paragraphs={paragraphsThree} />
                <FlexRow>
                    <Card title='JW.ORG' subtitle='www.jw.org' url='https://www.jw.org' thumbnail='/images/jw.jpg'/>
                </FlexRow>
			</Page>
		);
	}

}