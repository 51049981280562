export const mapAdminStatusToString = (status) => {
	switch(status) {
		case 200:
			return '';
		case 401:
			return 'Wrong password';
		case 404:
			return 'Username not found';
		default:
			return 'Something went wrong. Please try again later';
	}
}

export const mapGuestStatusToString = (status) => {
	switch(status) {
		case 200:
			return '';
		case 401:
			return 'Wrong group code';
		case 404:
			return 'Group name not found';
		default:
			return 'Something went wrong. Please try again later';
	}
}