import React from 'react';
import {Link} from 'react-router-dom';

import './admincontrolpanel.css';

export default class AdminControlPanel extends React.Component {

	render() {
		return (
			<div class='admincontrolpanel'>
				<div class='admincontrolpanel-row'>
				<Link class='admincontrolpanel-button' to='/admin/rsvp'>RSVP Status</Link>
				<Link class='admincontrolpanel-button' to='/admin/registry'>Registry Editing</Link>
				<Link class='admincontrolpanel-button' to='/admin/pages'>Page Editing</Link>
				</div>
				<div class='admincontrolpanel-row'>
				<Link class='admincontrolpanel-button' to='/admin/photos'>Photo Upload</Link>
				<Link class='admincontrolpanel-button' to='/admin/users'>User Setup</Link>
				<Link class='admincontrolpanel-button' to='/admin/database'>Database Management</Link>
				</div>
			</div>
		);
	}

}