import React from 'react'

import './coverphoto.css';

export default class CoverPhoto extends React.Component {

//<img src={this.props.src} />

	render() {
		const style = {
			backgroundImage: 'url(' + this.props.src + ')'
		};
		return (
			<div class='coverphoto' style={style}/>
		);
	}

}