import React from 'react';
import cookie from 'react-cookies';

import AuthCard from './authcard.js';

import {signIntoGuest, signOutGuest} from '../../utils/serverutils.js';
import {mapGuestStatusToString} from '../../utils/authutils.js';

export default class GuestAuth extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            guestusername: '',
            guesttoken: ''
        };
        
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
    }
    
    componentDidMount() {
		this.setState({
			guestusername: cookie.load('guestusername'),
			guesttoken: cookie.load('guesttoken')
		});
	}
    
    handleSignIn(username, code) {
		this.setState({
			signInError: ''
		});

		signIntoGuest(username, code).then(res => {
			this.setState({
				signInError: mapGuestStatusToString(res.status)
			});
			if (res.token) {
				// Successful sign in
				this.setState({
					guestusername: res.token.groupusername,
					guesttoken: res.token.token
				});
				cookie.save('guestusername', res.token.groupusername, {path: '/'});
				cookie.save('guesttoken', res.token.token, {path: '/'});
			}
		});
	}
    
    handleSignOut() {
		signOutGuest(this.state.guestusername, this.state.guesttoken).then(res => {
            // Clear the cookie regardless of server success
            cookie.remove('guestusername', {path:'/'});
            cookie.remove('guesttoken', {path:'/'});
            this.setState({
                guestusername: '',
                guesttoken: ''
            });
		})
	}
    
    render() {
        const childProps = {
            signOut: this.handleSignOut,
            guestusername: this.state.guestusername,
            guesttoken: this.state.guesttoken,
		};
        return (
            <div class='guestauth'>
                {!this.state.guesttoken && <p class='adminauth-greeting'>Have you received an invitation to the digital reception? Enter your invite group name and invite code below to complete your RSVP. If you have misplaced this information, please contact david.lianne.wedding@gmail.com</p>}
				{!this.state.guesttoken && <AuthCard tooltip='Enter your group name and code' error={this.state.signInError} usernamePlaceholder='group name' passwordPlaceholder='group code' handleSignIn={this.handleSignIn}/>}
				{this.state.guesttoken && this.props.children(childProps)}
            </div>
        );
    }
    
}