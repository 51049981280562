import React from 'react';

import './youtubevideo.css';

export default class YoutubeVideo extends React.Component {

	render() {
		return (
			<div class='youtubevideo'>
				<iframe class='youtubevideo-video' src={this.props.url} frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen />
			</div>
		);
	}

}