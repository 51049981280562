import React from 'react';

import Toggle from '../toggle.js';

import './rsvpcard.css';

export default class RSVPCard extends React.Component {
    
    render() {
        const submittedRsvpBefore = this.props.group && this.props.group.ceremonyrsvp != null && this.props.group.receptionrsvp != null;
        
        return (
            <div class='rsvpcard'>
                <div class='rsvpcard-header'>
                    <div class='rsvpcard-header-head'>
                        <p class='rsvpcard-header-head-title'>RSVP Information</p>
                        <p class='rsvpcard-header-head-identifier'>Signed in as {this.props.group && this.props.group.groupdisplayname}</p>
                    </div>
                    <div class='rsvpcard-header-tail'>
                        <button class='rsvpcard-header-tail-signout' onClick={this.props.signOut}>Sign out</button>
                    </div>
                </div>
                
            </div>
        );
    }
    
}