import React from 'react';
import {Link} from 'react-router-dom';

const generateLink = (to, title, index) => {
	return (<Link class='adminnavbar-link' key={'adminnavbar-link-' + index} to={to}>{title}</Link>);
}

const addLinkToList = (links, to, title, index) => {
	if (!title)
		return;

	if (index != 0) {
		const separator = (<p key={'adminnavbar-link-separator-' + index}>/</p>);
		links.push(separator);
	}
	links.push(generateLink(to, title, index));
}

const generateLinkUpToIndex = (pathSeparated, index) => {
	var path = '';
	for (var i=0; i <= index; i++) {
		path += '/' + pathSeparated[i];
	}
	return path;
}

const mapPathFragmentToText = (index, pathFragment) => {
	if (index == 0) {
		return 'Admin Console';
	} else if (index == 1) {
		switch(pathFragment) {
			case 'rsvp':
				return 'RSVP Status';
			case 'registry':
				return 'Registry Editing';
			case 'pages':
				return 'Page Editing';
			case 'photos':
				return 'Photo Upload';
			case 'users':
				return 'User Setup';
			case 'database':
				return 'Database Management';
			default:
				return '';
		}
	} else if (index == 2) {
        return pathFragment;
    }
	return '';
}

export const generateLinks = (path) => {
	if (!path)
		return [];
	const pathSeparated = path.split("/");
	const links = [];
	pathSeparated.shift();
	for (var pathFragmentIndex in pathSeparated) {
		const pathFragment = pathSeparated[pathFragmentIndex];
		const title = mapPathFragmentToText(pathFragmentIndex, pathFragment);
		addLinkToList(links, generateLinkUpToIndex(pathSeparated, pathFragmentIndex), title, pathFragmentIndex);
	}
	return links;
}