import React from 'react'

import AdminNavbar from './adminnavbar.js';
import AdminControlPanel from './admincontrolpanel.js';

import {generateComponentForPath} from '../../../utils/adminutils.js';

export default class AdminConsole extends React.Component {

	render() {
		return (
			<div class='adminconsole'>
				<AdminNavbar path={this.props.path} signOut={this.props.signOut}/>
				{generateComponentForPath(this.props.path, this.props.adminusername, this.props.admintoken)}
			</div>
		);
	}
}