import React from 'react'

import Header from './page/header.js';
import Body from './page/body.js';

import './page.css';

export default class Page extends React.Component {

	render() {
		return (
			<div class='page'>
				<Header {...this.props} />
				<Body>
					{this.props.children}
				</Body>
			</div>
		);
	}

}