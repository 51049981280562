import React from 'react';

import Footer from './body/footer.js';

import './body.css';

export default class Body extends React.Component {

	render() {
		return (
			<div class='body'>
				<div class='body-container'>
					<div class='body-content'>
						{this.props.children}
					</div>
					<Footer />	
				</div>
			</div>
		);
	}

}