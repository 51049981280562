import React from 'react';

import './toggle.css';

export default class Toggle extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.onToggle = this.onToggle.bind(this);
    }
    
    
    onToggle() {
        if (this.props.toggle) this.props.toggle();
    }
    
    render() {
        
        const insideStyle = {
            justifyContent: this.props.state ? 'flex-end' : 'flex-start',
            backgroundColor: this.props.state ? '#83a4ce' : '#cccccc'
        };
        
        const offLabelStyle = {
            color: this.props.state ? '#cccccc' : 'black'
        };
        
        const onLabelStyle = {
            color: this.props.state ? 'black' : '#cccccc'
        };
        
        return (
            <div class='toggle'>
                <p style={offLabelStyle} >{this.props.offlabel}</p>
                <div class='toggle-inside' style={insideStyle} onClick={this.onToggle} >
                    <div class='toggle-switch' />
                </div>
                <p style={onLabelStyle} >{this.props.onlabel}</p>
            </div>
        );
    }
    
}