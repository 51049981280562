import React from 'react';

import FlexRow from '../flexrow.js';
import Article from '../article.js';
import Card from '../card.js';
import RSVPCard from './rsvpcard.js';

import {getGuestGroup, updateGuestGroupRsvp, getZoomInfo, getStreamInfo} from '../../../utils/serverutils.js'

export default class RSVPConsole extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            group: null,
            rsvpSubmitted: '',
            ceremonyToggleOn: false,
            receptionToggleOn: false,
            zoominfo: {
                link: '',
                meetingid: '',
                passcode: '',
            },
            ceremonystream: ''
        };
        
        this.toggleCeremony = this.toggleCeremony.bind(this);
        this.toggleReception = this.toggleReception.bind(this);
        this.submitRsvp = this.submitRsvp.bind(this);
    }
    
    componentDidMount() {
        this.updateGroupContent();
        this.updateZoomInfo();
        this.updateStreamInfo();
    }
    
    updateGroupContent() {
        getGuestGroup(this.props.guestusername, this.props.guesttoken).then(res => {
            if (res.error) {
                this.props.signOut();
                return;
            }
            this.setState({
                group: res.result,
                ceremonyToggleOn: res.result ? res.result.ceremonyrsvp : false,
                receptionToggleOn: res.result ? res.result.receptionrsvp : false
            });
        })
    }
    
    updateZoomInfo() {
        getZoomInfo(this.props.guestusername, this.props.guesttoken).then(res => {
            this.setState({
                zoominfo: {
                    link: res.result ? res.result.link : '',
                    meetingid: res.result ? res.result.meetingid : '',
                    passcode: res.result ? res.result.passcode : ''
                },
            });
        });
    }
    
    updateStreamInfo() {
        getStreamInfo().then(res => {
            this.setState({
                ceremonystream: res.result ? res.result.link : ''
            });
        })
    }
    
    toggleCeremony() {
        this.setState({
            ceremonyToggleOn: !this.state.ceremonyToggleOn,
            rsvpSubmitted: ''
        });
    }

    toggleReception() {
        this.setState({
            receptionToggleOn: !this.state.receptionToggleOn,
            rsvpSubmitted: ''
        });
    }
    
    submitRsvp() {
        updateGuestGroupRsvp(this.props.guestusername, this.props.guesttoken, this.state.ceremonyToggleOn, this.state.receptionToggleOn).then(res => {
            this.setState({
                rsvpSubmitted: 'Thank you for submitting your RSVP! That\'s all there is to do for now. Please check back again later for more information and feel free to explore the website.'
            });
            this.updateGroupContent();
        })
    }
    
    render() {
        const previewNickname = !this.state.group ? '' : this.state.group.groupnickname ? this.state.group.groupnickname : this.state.group.groupdisplayname;
        
        const rsvpParagraphHeader = [
            'Hi ' + previewNickname + '! On the tabs above, you can check out our story, view photos, watch the wedding, and access our registry.',
        ];
        const rsvpParagraphIntro = [
            'It\'s the big day! Thank you for checking in and being a part of it. Not everyone got a chance to RSVP so we are still providing the information to watch the stream and join the Zoom meeting regardless.',
        ];
        const rsvpParagraphStream = [
            'We are aiming to start the ceremony at 4PM EST. Unfortunately due to weather, there is a possibility that the livestream of the ceremony will start early or later than expected.',
            'Don’t worry though! You will still be able to watch the whole event even if you don’t start watching when it goes “live”. Just make sure to rewind the stream to the beginning if you want to see it from the start.',
            'You can watch the ceremony by going to the \'Watch\' tab above or by clicking the button below.',
        ];
        const rsvpParagraphZoom = [
            'We don’t foresee any complications with the Zoom reception. We will be joining it at 7:00 PM. If possible, please join it early, so that you can be placed into a breakout room in time.',
            'For your convenience, the details for the meeting are also available below. Please follow these instructions when joining so that we can place you into your virtual group!',
            '1. Join the Zoom meeting with your Name exactly as it is on the invitation.',
            '2. Please wait in the Zoom meeting lobby until we can move you into your assigned breakout room.',
            '3. Do not leave the meeting once you are in your breakout room.',
            '4. We will be moving through the breakout rooms to say hello!',
            'Please find the Zoom information below. You can enter it manually into Zoom or click the button to join automatically!'
        ];
        return (
            <div classs='rsvpconsole'>
                {this.state.group && <Article paragraphs={rsvpParagraphHeader}/>}
                
                <FlexRow>
                    {this.state.group && <Article paragraphs={rsvpParagraphIntro}/>}
                    {this.state.group && <Article paragraphs={rsvpParagraphStream}/>}
                    {this.state.group && <Card url={this.state.ceremonystream} title='Ceremony Stream' subtitle='YouTube Live'/>}
                    {this.state.group && <Article paragraphs={rsvpParagraphZoom}/>}
                    {this.state.group && <Card url={this.state.zoominfo.link} title='Zoom Details' subtitle={'Meeting ID: ' + this.state.zoominfo.meetingid} message={'Passcode: ' + this.state.zoominfo.passcode}/>}
                </FlexRow>
                <RSVPCard group={this.state.group} signOut={this.props.signOut} handleSubmit={this.submitRsvp} ceremonyToggleOn={this.state.ceremonyToggleOn} receptionToggleOn={this.state.receptionToggleOn} toggleCeremony={this.toggleCeremony} toggleReception={this.toggleReception}/>
                {this.state.rsvpSubmitted && <Article paragraphs={[this.state.rsvpSubmitted]} />}
            </div>
        );
    }
    
}