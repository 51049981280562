import React from 'react';

import AdminControlPanel from '../componentsv2/webparts/admin/admincontrolpanel.js';
import AdminUsers from '../componentsv2/webparts/admin/adminusers.js';
import AdminDatabase from '../componentsv2/webparts/admin/admindatabase.js';

const generateRsvpComponentForSeparatedPath = (pathSeparated, adminusername, admintoken) => {
	return (
		<div class='adminconsole-rsvp'>
			rsvp
		</div>
	);
}

const generateRegistryComponentForSeparatedPath = (pathSeparated, adminusername, admintoken) => {
	return (
		<div class='adminconsole-registry'>
			registry
		</div>
	);
}

const generatePagesComponentForSeparatedPath = (pathSeparated, adminusername, admintoken) => {
	return (
		<div class='adminconsole-pages'>
			pages
		</div>
	);
}

const generatePhotosComponentForSeparatedPath = (pathSeparated, adminusername, admintoken) => {
	return (
		<div class='adminconsole-photos'>
			photos
		</div>
	);
}

const generateUsersComponentForSeparatedPath = (pathSeparated, adminusername, admintoken) => {
	return (
		<AdminUsers adminusername={adminusername} admintoken={admintoken} path={pathSeparated}/>
	);
}

const generateDatabaseComponentForSeparatedPath = (pathSeparated, adminusername, admintoken) => {
	return (
		<AdminDatabase adminusername={adminusername} admintoken={admintoken}/>
	);
}

export const generateComponentForPath = (path, adminusername, admintoken) => {
	const pathSeparated = path.split('/');
	pathSeparated.shift();
	if (pathSeparated.length == 1) {
		return (<AdminControlPanel />);
	} else {
		switch(pathSeparated[1]) {
			case 'rsvp':
				return generateRsvpComponentForSeparatedPath(pathSeparated, adminusername, admintoken);
			case 'registry':
				return generateRegistryComponentForSeparatedPath(pathSeparated, adminusername, admintoken);
			case 'pages':
				return generatePagesComponentForSeparatedPath(pathSeparated, adminusername, admintoken);
			case 'photos':
				return generatePhotosComponentForSeparatedPath(pathSeparated, adminusername, admintoken);
			case 'users':
				return generateUsersComponentForSeparatedPath(pathSeparated, adminusername, admintoken);
			case 'database':
				return generateDatabaseComponentForSeparatedPath(pathSeparated, adminusername, admintoken);
			default:
				return (<AdminControlPanel />);
		}
	}
}