import React from 'react'

// import Header from '../components/header.js';
// import Footer from '../components/footer.js';

import Page from '../componentsv2/page.js';
import AdminAuth from '../componentsv2/auth/adminauth.js';
import AdminConsole from '../componentsv2/webparts/admin/adminconsole.js';

export default class AdminPage extends React.Component {

	render() {
		return (
			<Page admin>
				<AdminAuth>
					{props => <AdminConsole path={this.props.path} {...props}/>}
				</AdminAuth>
			</Page>
		);
	}

}