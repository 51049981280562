import React from 'react';

import AdminUsersTable from './adminusers/adminuserstable.js';
import AdminManageUser from './adminusers/adminmanageuser.js';

import {getAdmins, getGuestGroups, createGuestGroup, deleteGuestGroup, updateGuestGroupDisplayName, updateGuestGroupNickname} from '../../../utils/serverutils.js';

import './adminusers.css';

export default class AdminUsers extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            adminData: [],
            guestGroupData: [],
        };
        
        this.updateAdminData = this.updateAdminData.bind(this);
        this.createAdmin = this.createAdmin.bind(this);
        
        this.updateGuestGroupData = this.updateGuestGroupData.bind(this);
        this.createGuestGroup = this.createGuestGroup.bind(this);
        this.deleteGuestGroup = this.deleteGuestGroup.bind(this);
        
        this.handleUpdateGuestGroupDisplayName = this.handleUpdateGuestGroupDisplayName.bind(this);
        this.handleUpdateGuestGroupNickame = this.handleUpdateGuestGroupNickame.bind(this);
    }
    
    componentDidMount() {
        this.updateAdminData();
        this.updateGuestGroupData();
        this.setStaticVariables();
    }
    
    updateAdminData() {
        getAdmins(this.props.adminusername, this.props.admintoken).then(res => {
            if (res.error) return;
            const data = res.result;
            const dict = {};
            for (var i in data) {
                const admin = data[i];
                dict[admin.username] = admin;
            }
            this.setState({
                adminData: dict
            })
        });
    }
    
    updateGuestGroupData() {
        getGuestGroups(this.props.adminusername, this.props.admintoken).then(res => {
            if (res.error) return;
            const data = res.result;
            const dict = {}
            for (var i in data) {
                const guestgroup = data[i];
                dict[guestgroup.groupusername] = guestgroup;
            }
            this.setState({
                guestGroupData: dict
            });
        });
    }
    
    setStaticVariables() {
        this.adminCreator = {
            fields: ['username', 'password'],
            button: {
                label: 'create admin', 
                action: this.createAdmin
            }
        };
        this.adminFields = ['username', 'password'];
        this.adminFieldLabels = ['Username', 'Password', 'Delete Admin'];
        this.adminButtons = [
            {
                label: 'Delete',
                action: undefined
            }
        ];
        this.guestGroupCreator = {
            fields: ['group username', 'group display name', 'group nickname'],
            button: {
                label: 'create group',
                action: this.createGuestGroup
            }
        };
        this.guestGroupFields = ['groupusername', 'groupdisplayname', 'groupnickname', 'groupcode', 'ceremonyrsvp', 'receptionrsvp'];
        this.guestGroupFieldLabels = ['Group Username', 'Group Display Name', 'Group Nickname', 'Group Code', 'Ceremony RSVP', 'Reception RSVP', 'Manage Group', 'Delete Group'];
        this.guestGroupButtons = [
            {
                label: 'Manage',
                tolink: '/admin/users/$groupusername'
            },
            {
                label: 'Delete',
                action: undefined
            }
        ];
    }
    
    createAdmin(username, password) {
        
    }
    
    createGuestGroup(groupusername, groupdisplayname, groupnickname) {
        createGuestGroup(this.props.adminusername, this.props.admintoken, groupusername, groupdisplayname, groupnickname).then(res => {
            this.updateGuestGroupData();
        });
    }
    
    deleteGuestGroup(entry) {
        deleteGuestGroup(this.props.adminusername, this.props.admintoken, entry.groupusername).then(res => {
           this.updateGuestGroupData(); 
        });
    }
    
    handleUpdateGuestGroupDisplayName(groupusername, newdisplayname) {
        updateGuestGroupDisplayName(this.props.adminusername, this.props.admintoken, groupusername, newdisplayname).then(res => {
            this.updateGuestGroupData();
        })
    }
    
    handleUpdateGuestGroupNickame(groupusername, newnickname) {
        updateGuestGroupNickname(this.props.adminusername, this.props.admintoken, groupusername, newnickname).then(res => {
            this.updateGuestGroupData();
        })
    }
    
    render() {
        
        return (
            <div class='adminusers'>
                {this.props.path.length == 2 && <AdminUsersTable title='Admin Setup' creator={this.adminCreator} rawdata={this.state.adminData} fields={this.adminFields} fieldlabels={this.adminFieldLabels} buttons={this.adminButtons}/>}
                {this.props.path.length == 2 && <AdminUsersTable title='Guest Group Setup' creator={this.guestGroupCreator} rawdata={this.state.guestGroupData} fields={this.guestGroupFields} fieldlabels={this.guestGroupFieldLabels} buttons={this.guestGroupButtons}/>}
                {this.props.path.length == 3 && <AdminManageUser guestgroup={this.state.guestGroupData[this.props.path[2]]} handleSaveDisplayName={this.handleUpdateGuestGroupDisplayName} handleSaveNickname={this.handleUpdateGuestGroupNickame} />}
            </div>
        );
    }
    
}