import React from 'react';

import './card.css';

export default class Card extends React.Component {

	constructor(props) {
		super(props);

		this.redirect = this.redirect.bind(this);
	}

	redirect() {
		window.open(this.props.url);
	}

	render() {

		const thumbnailStyle = {
			backgroundImage: 'url(' + this.props.thumbnail + ')'
		};

		return (
			<div class='card' onClick={this.redirect}>
				{this.props.thumbnail && <div class='card-thumbnail' style={thumbnailStyle}/>}
				<div class='card-text'>
					<p class='card-text-title'>{this.props.title}</p>
					<p class='card-text-subtitle'>{this.props.subtitle}</p>
					<p class='card-text-message'>{this.props.message}</p>
				</div>
			</div>
		);
	}

}