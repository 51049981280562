const SERVER_ENDPOINT = /*'http://localhost:8080/';*/'https://api.davidandlianne.com/';
const API_ENDPOINT = 'api/v1/';

const ADMIN_ENDPOINT = 'admins';
const ADMIN_SIGNIN_ENDPOINT = '/$/signin'; //Replace $ with username

const DB_ENDPOINT = 'database';
const DB_QUERY_ENDPOINT = '/query';

const GUESTGROUP_ENDPOINT = 'guestgroups';
const GUESTGROUP_GROUPUSERNAME_ENDPOINT = '/$'; // Replace $ with groupusername
const GUESTGROUP_CREATE_ENDPOINT = '/create'; 
const GUESTGROUP_SIGNIN_ENDPOINT = '/signin';
const GUESTGROUP_DISPLAYNAME_ENDPOINT = '/displayname';
const GUESTGROUP_NICKNAME_ENDPOINT = '/nickname';
const GUESTGROUP_RSVP_ENDPOINT = '/rsvp';

const ZOOM_ENDPOINT = 'zoom';

const STREAM_ENDPOINT = 'stream';

const BACKEND_HEADERS = {
	'Accept': 'application/json',
  	'Content-Type': 'application/json'
};

const generateAdminEndpoint = () => {
    const constructedString = SERVER_ENDPOINT + API_ENDPOINT + ADMIN_ENDPOINT;
    return constructedString;
}

const generateAdminSigninEndpoint = (parameter) => {
	const constructedString = SERVER_ENDPOINT + API_ENDPOINT + ADMIN_ENDPOINT + ADMIN_SIGNIN_ENDPOINT;
	const replacedString = constructedString.replace('$', parameter);
	return replacedString;
}

// ------------------- ADMINS -------------------

/**
 * POST admins/{username}/signin
 * body { username, password }
 * return { status, token: { username, token }}
 */
export const signIntoAdmin = (username, password) => {
	return fetch(generateAdminSigninEndpoint(username), {
		method: 'POST',
		headers: BACKEND_HEADERS,
		body: JSON.stringify({
			username,
			password
		})
	}).then(res => {
		return res.json();
	}).catch(res => {
		return {
			status: 500
		};
	});
};

/**
 * DELETE admins/{username}/signin
 * body { token }
 * return { status }
 */

export const signOutAdmin = (username, token) => {
	return fetch(generateAdminSigninEndpoint(username), {
		method: 'DELETE',
		headers: BACKEND_HEADERS,
		body: JSON.stringify({
			token
		})
	}).then(res => {
        return res.json();
    }).catch(res => {
        return {
            status: 500
        };
    });
}

/**
 * POST admins
 * body { token, username }
 * return { result, error }
 */
export const getAdmins = (username, token) => {
    return fetch(generateAdminEndpoint(), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            username,
            token
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            result: [],
            error: 500
        };
    });
}

// ------------------- DATABASE -------------------

const generateDatabaseQueryEndpoint = () => {
    const constructedString = SERVER_ENDPOINT + API_ENDPOINT + DB_ENDPOINT + DB_QUERY_ENDPOINT;
    return constructedString;
}

/**
 * POST database/query
 * body { token, username, query }
 * return { result, error }
 */

export const sendDBQuery = (username, token, query) => {
    return fetch(generateDatabaseQueryEndpoint(), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            username,
            token,
            query
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            staatus: 500
        };
    })
}

// ------------------- GUEST GROUPS -------------------

const generateGuestGroupEndpoint = () => {
    const constructedString = SERVER_ENDPOINT + API_ENDPOINT + GUESTGROUP_ENDPOINT;
    return constructedString;
}

const generateGuestGroupSignInEndpoint = (groupusername) => {
    const constructedString = SERVER_ENDPOINT + API_ENDPOINT + GUESTGROUP_ENDPOINT + GUESTGROUP_GROUPUSERNAME_ENDPOINT + GUESTGROUP_SIGNIN_ENDPOINT;
    const replacedString = constructedString.replace('$', groupusername);
    return replacedString;
}

const generateGuestGroupGroupUsernameEndpoint = (groupusername) => {
    const constructedString = SERVER_ENDPOINT + API_ENDPOINT + GUESTGROUP_ENDPOINT + GUESTGROUP_GROUPUSERNAME_ENDPOINT;
    const replacedString = constructedString.replace('$', groupusername);
    return replacedString;
}

const generateGuestGroupUpdateDisplayNameEndpoint = (groupusername) => {
    const constructedString = SERVER_ENDPOINT + API_ENDPOINT + GUESTGROUP_ENDPOINT + GUESTGROUP_GROUPUSERNAME_ENDPOINT + GUESTGROUP_DISPLAYNAME_ENDPOINT;
    const replacedString = constructedString.replace('$', groupusername);
    return replacedString;
}

const generateGuestGroupUpdateNicknameEndpoint = (groupusername) => {
    const constructedString = SERVER_ENDPOINT + API_ENDPOINT + GUESTGROUP_ENDPOINT + GUESTGROUP_GROUPUSERNAME_ENDPOINT + GUESTGROUP_NICKNAME_ENDPOINT;
    const replacedString = constructedString.replace('$', groupusername);
    return replacedString;
}

const generateGuestGroupUpdateRsvpEndpoint = (groupusername) => {
    const constructedString = SERVER_ENDPOINT + API_ENDPOINT + GUESTGROUP_ENDPOINT + GUESTGROUP_GROUPUSERNAME_ENDPOINT + GUESTGROUP_RSVP_ENDPOINT;
    const replacedString = constructedString.replace('$', groupusername);
    return replacedString;
}

const generateGuestGroupCreateEndpoint = (groupusername) => {
    const contructedString = SERVER_ENDPOINT + API_ENDPOINT + GUESTGROUP_ENDPOINT + GUESTGROUP_GROUPUSERNAME_ENDPOINT + GUESTGROUP_CREATE_ENDPOINT;
    const replacedString = contructedString.replace('$', groupusername);
    return replacedString;
}

/**
 * POST guestgroups/{groupusername}/signin
 * body { groupcode }
 * return { status, token: { groupusername, token }}
 */
export const signIntoGuest = (groupusername, groupcode) => {
    return fetch(generateGuestGroupSignInEndpoint(groupusername), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            groupcode
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            status: 500
        };
    });
}

export const signOutGuest = (groupusername, token) => {
    return fetch(generateGuestGroupSignInEndpoint(groupusername), {
		method: 'DELETE',
		headers: BACKEND_HEADERS,
		body: JSON.stringify({
			token
		})
	}).then(res => {
        return res.json();
    }).catch(res => {
        return {
            status: 500
        };
    });
}

/**
 * POST guestgroups
 * body { token, username }
 * return { result, error } 
 */
export const getGuestGroups = (username, token) => {
    return fetch(generateGuestGroupEndpoint(), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            username,
            token
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            error: 500
        };
    });
}

/**
 * POST guestgroups/{groupusername}
 * body {token}
 * return { result, error}
 */
export const getGuestGroup = (groupusername, token) => {
    return fetch(generateGuestGroupGroupUsernameEndpoint(groupusername), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            token
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            error: 500
        };
    })
}

/**
 * POST guestgroups/{groupusername}/displayname
 * body {token, username, groupdisplayname}
 * return {result, error}
 */
export const updateGuestGroupDisplayName = (username, token, groupusername, groupdisplayname) => {
    return fetch(generateGuestGroupUpdateDisplayNameEndpoint(groupusername), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            username,
            token,
            groupdisplayname
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            error: 500
        };
    })
}

/**
 * POST guestgroups/{groupusername}/nickname
 * body {token, username, groupnickname}
 * return {result, error}
 */
export const updateGuestGroupNickname = (username, token, groupusername, groupnickname) => {
    return fetch(generateGuestGroupUpdateNicknameEndpoint(groupusername), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            username,
            token,
            groupnickname
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            error: 500
        };
    })
}

/**
 * POST guestgroups/{groupusername}/rsvp
 * body {token, ceremonyrsvp, receptionrsvp}
 * return {result, error}
 */
export const updateGuestGroupRsvp = (groupusername, token, ceremonyrsvp, receptionrsvp) => {
    return fetch(generateGuestGroupUpdateRsvpEndpoint(groupusername), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            token,
            ceremonyrsvp,
            receptionrsvp
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            error: 500
        };
    })
}

/**
 * POST guestgroups/{groupusername}/create
 * body { token, username, groupdisplayname, groupnickname }
 * return { result, error }
 */
export const createGuestGroup = (username, token, groupusername, groupdisplayname, groupnickname) => {
    return fetch(generateGuestGroupCreateEndpoint(groupusername), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            username,
            token,
            groupdisplayname,
            groupnickname
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            error: 500,
        };
    })
}

/**
 * DELETE guestgroups/{groupusername}
 * body { token, username }
 * return { groupresult, guestresult, grouperror, guesterror }
 */
export const deleteGuestGroup = (username, token, groupusername) => {
    return fetch(generateGuestGroupGroupUsernameEndpoint(groupusername), {
        method: 'DELETE',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            username,
            token
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            grouperror: 500,
            guesterror: 500
        };
    });
}

const generateZoomEndpoint = () => {
    return SERVER_ENDPOINT + API_ENDPOINT + ZOOM_ENDPOINT;
}

/**
 * POST zoom
 * body { groupusername, token }
 * return {}
 */
export const getZoomInfo = (groupusername, token) => {
    return fetch(generateZoomEndpoint(), {
        method: 'POST',
        headers: BACKEND_HEADERS,
        body: JSON.stringify({
            groupusername,
            token
        })
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            error: 500
        }
    });
}

const generateStreamEndpoint = () => {
    return SERVER_ENDPOINT + API_ENDPOINT + STREAM_ENDPOINT;
}

/**
 * GET stream
 * return {}
 */
export const getStreamInfo = () => {
    return fetch(generateStreamEndpoint(), {
        method: 'GET',
        headers: BACKEND_HEADERS
    }).then(res => {
        return res.json();
    }).catch(res => {
        return {
            error: 500
        }
    });
}