import React from 'react'

import './photosquare.css';

export default class PhotoSquare extends React.Component {

	constructor(props) {
		super(props);

		this.imageClicked = this.imageClicked.bind(this);
	}

	imageClicked() {
		if(this.props.imageClicked)
			this.props.imageClicked(this.props.src);
	}

	render() {

		const percentage = 100/this.props.countInRow;

		const style = {
			paddingTop: percentage + '%',
			backgroundImage: 'url(' + this.props.src + ')'
		}

		return (
			<div class='photosquare' style={style} onClick={this.imageClicked} />
		);
	}

}