import React from 'react';

import Page from '../componentsv2/page.js';
import Article from '../componentsv2/webparts/article.js';
import FlexRow from '../componentsv2/webparts/flexrow.js';
import YoutubeVideo from '../componentsv2/webparts/youtubevideo.js';
import Card from '../componentsv2/webparts/card.js';

import {getStreamInfo} from '../utils/serverutils.js'

export default class WatchPage extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            ceremonystream: ''
        };
    }
    
    componentDidMount() {
        this.updateStreamInfo();
    }
    
    updateStreamInfo() {
        getStreamInfo().then(res => {
            this.setState({
                ceremonystream: res.result ? res.result.link : ''
            });
        })
    }

	render() {

		const paragraphs = [
            'Thank you so much for making our big day extra special.',
            'You can find the full wedding ceremony recording below.',
		];

		return (
			<Page watch>
				<Article paragraphs={paragraphs}/>
				<FlexRow>
                    {this.state.ceremonystream && <YoutubeVideo url={this.state.ceremonystream} />}
                    {this.state.ceremonystream && <Card url={this.state.ceremonystream} title="Ceremony Stream" subtitle="Click to watch on YouTube"/>}
				</FlexRow>
			</Page>
		);
	}

}