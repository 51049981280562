import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom';

import HomePage from './pages/home.js';
import PhotosPage from './pages/photos.js';
import WatchPage from './pages/watch.js';
import RSVPPage from './pages/rsvp.js';
import RegistryPage from './pages/registry.js';
import AdminPage from './pages/admin.js';

import './style/global.css'
import './index.css';

class Website extends React.Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route path='/photos'>
						<PhotosPage />
					</Route>
					<Route path='/watch'>
						<WatchPage />
					</Route>
					<Route path='/rsvp'>
						<RSVPPage />
					</Route>
					<Route path='/registry'>
						<RegistryPage />
					</Route>
					<Route path='/admin/rsvpstatus'>
						<AdminPage path={[{link:'/admin/rsvpstatus',name:'RSVP Status'}]}/>
					</Route>
					<Route path='/admin/registryediting'>
						<AdminPage path={[{link:'/admin/registryediting',name:'Registry Editing'}]}/>
					</Route>
					<Route path='/admin/pageediting'>
						<AdminPage path={[{link:'/admin/pageediting',name:'Page Editing'}]}/>
					</Route>
					<Route path='/admin/photoupload'>
						<AdminPage path={[{link:'/admin/photoupload',name:'Photo Upload'}]}/>
					</Route>
                    <Route path='/admin/usersetup/:groupusername/:guestname' children={(props) => {
                            const groupusername = props.match.params.groupusername;
                            const guestname = props.match.params.guestname;
                            return <AdminPage path={[
                                    {link:'/admin/usersetup',name:'User Setup'},
                                    {link:`/admin/usersetup/${groupusername}`,name:groupusername},
                                    {link:`/admin/usersetup/${groupusername}/${guestname}`,name:guestname}
                                ]} groupusername={groupusername} guestname={guestname}/>
                        }} />
                    <Route path='/admin/usersetup/:groupusername' children={(props) => {
                            const groupusername = props.match.params.groupusername
                            return <AdminPage path={[
                                    {link:'/admin/usersetup',name:'User Setup'},
                                    {link:`/admin/usersetup/${groupusername}`,name:groupusername}
                                ]} groupusername={groupusername}/>
                        }} />
					<Route path='/admin/usersetup'>
						<AdminPage path={[{link:'/admin/usersetup',name:'User Setup'}]}/>
					</Route>
					<Route path='/admin/databasemanagement'>
						<AdminPage path={[{link:'/admin/databasemanagement',name:'Database Management'}]}/>
					</Route>
					<Route path='/admin/rsvp'>
						<AdminPage path='/admin/rsvp' />
					</Route>
					<Route path='/admin/registry'>
						<AdminPage path='/admin/registry' />
					</Route>
					<Route path='/admin/pages'>
						<AdminPage path='/admin/pages' />
					</Route>
					<Route path='/admin/photos'>
						<AdminPage path='/admin/photos' />
					</Route>
                    <Route path='/admin/users/:groupusername' children={(props) => {
                        const groupusername = props.match.params.groupusername;
                        return <AdminPage path={'/admin/users/' + groupusername} />   
                    }} />
					<Route path='/admin/users'>
						<AdminPage path='/admin/users' />
					</Route>
					<Route path='/admin/database'>
						<AdminPage path='/admin/database' />
					</Route>
					<Route path='/admin'>
						<AdminPage path='/admin' />
					</Route>
					<Route path='/'>
						<HomePage />
					</Route>
				</Switch>
			</Router>
		);
	}
}

ReactDOM.render(<Website />, document.getElementById("root"));