import React from 'react'

import Page from '../componentsv2/page.js';
import Card from '../componentsv2/webparts/card.js';
import FlexRow from '../componentsv2/webparts/flexrow.js';
import Gallery from '../componentsv2/webparts/gallery.js';
import Article from '../componentsv2/webparts/article.js';

export default class PhotosPage extends React.Component {

	constructor(props) {
		super(props);

		const images = this.generateImageUrls();
		this.state = {
			images
		};

	}

	generateImageUrls() {
		const total = 62;
		const images = [];
		for (var i = 0; i < total; i ++) {
			const thumbnailUrl = '/images/gallery/thumbnails/' + i + '.jpg';
            const imageUrl = '/images/gallery/fullres/' + i + '.jpg';
            const image = {
                thumbnailUrl,
                imageUrl
            };
            images.push(image);
            
		}
		return images;
	}

	render() {
		return (
			<Page photos>
				<Article paragraphs={['Thank you for making our wedding a blast! Check out the link below for our wedding pictures.']}/>
				<FlexRow>
					<Card title="Our Album" subtitle="photos.google.com" url="https://photos.app.goo.gl/8Vrw7YVjAf2p2YMX6" thumbnail="images/gallery/thumbnail.jpg"/>
				</FlexRow>
				<Article paragraphs={['David and Lianne over the years <3']}/>
				<Gallery images={this.state.images} maxRowCount={4}/>
			</Page>
		);
	}

}