import React from 'react';

import {sendDBQuery} from '../../../utils/serverutils.js'

import './admindatabase.css';

export default class AdminDatabase extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			querytext: '',
			outputlog: [],
            submitblocked: false,
		};

		this.handleSQLSubmit = this.handleSQLSubmit.bind(this);
		this.handleSQLTextChange = this.handleSQLTextChange.bind(this);
		this.handleClearOutput = this.handleClearOutput.bind(this);
	}

	handleSQLSubmit(event) {
		event.preventDefault();
		if (!this.state.querytext) return;
        this.setState({
            submitblocked: true
        });
        sendDBQuery(this.props.adminusername, this.props.admintoken, this.state.querytext).then(res => {
            var result = '';
            if (res.error) result = JSON.stringify(res.error);
            else result = JSON.stringify(res.result);
            this.setState({
                outputlog: [{
                    query: this.state.querytext,
                    output: result,
                }, ...this.state.outputlog],
                querytext: '',
                submitblocked: false,
            });
        });
	}

	handleSQLTextChange(event) {
		this.setState({
			querytext: event.target.value
		});
	}

	handleClearOutput() {
		this.setState({
			querytext: '',
			outputlog: []
		});
	}

	render() {
		const renderedOutput = [];
		for (var index in this.state.outputlog) {
			const outputlogEntry = this.state.outputlog[index];
			const renderedEntry = (
				<div class='admindatabase-output-entry' key={'admindatabase-output-entry-' + index}>
					<p>{'> ' + outputlogEntry.query}</p>
					<p>{'' + outputlogEntry.output}</p>
				</div>
			);
			renderedOutput.push(renderedEntry);
		}

		return (
			<div class='admindatabase'>
				<form class='admindatabase-controls' onSubmit={this.handleSQLSubmit}>
					<input type='text' class='admindatabase-controls-textinput' placeholder='Enter your SQL query' value={this.state.querytext} onChange={this.handleSQLTextChange}/>
					<button type='submit' class='admindatabase-controls-submit' disabled={this.state.submitblocked || !this.state.querytext}>Submit</button>
					<button class='admindatabase-controls-submit' onClick={this.handleClearOutput}>Clear</button>
				</form>
				<div class='admindatabase-output'>
					{renderedOutput}
				</div>
			</div>
		);
	}

}