import React from 'react';

import PhotoSquare from './photosquare.js';
import PhotoViewer from './photoviewer.js';

import './gallery.css';

export default class Gallery extends React.Component {

	constructor(props) {
		super(props);

		const photoSquares = this.generatePhotoSquares();
		this.state = {
			photoSquares,
			viewingImage: undefined,
		};

		this.selectedImage = this.selectedImage.bind(this);
        this.closeViewer = this.closeViewer.bind(this);
        this.nextImage = this.nextImage.bind(this);
        this.prevImage = this.prevImage.bind(this);
	}

	selectedImage(index) {
        console.log(index);
		this.setState({
			viewingImage: index
		});
	}
    
    closeViewer() {
        this.setState({
            viewingImage: undefined
        });
    }
    
    nextImage() {
        const nextImage = this.state.viewingImage == undefined ? 0 : (this.state.viewingImage + 1) % this.props.images.length;
        this.setState({
            viewingImage: nextImage
        });
    }
    
    prevImage() {
        var prevImage = this.state.viewingImage == undefined ? 0 : (this.state.viewingImage - 1) % this.props.images.length;
        if (prevImage < 0) prevImage = this.props.images.length - 1;
        this.setState({
            viewingImage: prevImage
        });
    }

	generateCountMapping() {
		const total = this.props.images.length;
		const maxRowCount = this.props.maxRowCount ? this.props.maxRowCount : 7;
		const splitModifier = Math.floor((total - 1)/maxRowCount) + 1;
		const splitRows = Math.min(maxRowCount, splitModifier);
		const fullRows = Math.max(0, splitModifier - maxRowCount);
		const countToSplit = total - maxRowCount * fullRows;
		const countToEachEvenSplit = Math.floor(countToSplit / splitRows);
		const countToDivvyToSplits = countToSplit % splitRows;
		const mapping = []
		for (var i = 0; i < splitRows; i++) {
			if (i < countToDivvyToSplits) {
				mapping.push(countToEachEvenSplit + 1);
			} else {
				mapping.unshift(countToEachEvenSplit);
			}
		}
		for (i = 0; i < fullRows; i++) {
			mapping.push(maxRowCount);
		}
		return mapping;
	}

	generatePhotoSquares() {
		if (!this.props.images)
			return [];
		const mapping = this.generateCountMapping();
		var index = 0;
		const rows = [];
		for (var rowIndex in mapping) {
			const rowCount = mapping[rowIndex];
			const rowItems = [];
			for (var i = 0; i < rowCount; i++) {
				const imageUrl = this.props.images[index];
                const constindex = index;
				const rowItem = (
					<PhotoSquare src={imageUrl.thumbnailUrl} countInRow={rowCount} key={'gallery-photosquare-' + index} imageClicked={() => {this.selectedImage(constindex)}}/>
				);
				rowItems.push(rowItem);
				index++;
			}
			const row = (
				<div class='gallery-row' key={'gallery-row-' + rowIndex}>
					{rowItems}
				</div>
			);
			rows.push(row);
		}
		return rows;
	}

	render() {
        console.log(this.props.images[this.state.viewingImage]);
		return (
			<div class='gallery'>
				{this.state.viewingImage != undefined && <PhotoViewer imageUrl={this.props.images[this.state.viewingImage].imageUrl} closeViewer={this.closeViewer} nextImage={this.nextImage} prevImage={this.prevImage} />}
				{this.state.photoSquares}
			</div>
		);
	}

}