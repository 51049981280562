import React from 'react';

import './flexrow.css';

export default class FlexRow extends React.Component {

	render() {
		return (
			<div class='flexrow'>
				{this.props.children}
			</div>
		);
	}

}