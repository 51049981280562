import React from 'react';
import {Link} from 'react-router-dom';

import './adminuserstable.css';

export default class AdminUsersTable extends React.Component {
/*
 * props {
 *   title,
 *   creator {
 *     fields,
 *     button {
 *       label,
 *       action
 *     }
 *   },
 *   rawadata [],
 *   fields [],
 *   fieldlabels [],
 *   buttons [{
 *     label,
 *     action
 *   }]
 * }
 */
    
    constructor(props) {
        super(props);
        
        this.state = {
            creatorbuttonenabled: false,
            ...this.createEmptyCreatorFields(props)
        };
        
//        this.allCreatorFieldsFilled = this.allCreatorFieldsFilled.bind(this);
        this.onChangeCreatorField = this.onChangeCreatorField.bind(this);
        this.onSubmitCreator = this.onSubmitCreator.bind(this);
        this.onClickEntry = this.onClickEntry.bind(this);
    }
    
    createEmptyCreatorFields(props) {
        if (!props.creator) return;
        const fieldValues = {};
        for (var i in props.creator.fields) {
            const field = props.creator.fields[i];
            fieldValues['creator-' + field] = '';
        }
        return fieldValues
    }
    
    checkCreatorFieldsFilled() {
        this.setState({
            creatorbuttonenabled: this.allCreatorFieldsFilled()
        });
    }
    
    allCreatorFieldsFilled() {
        for(var i in this.props.creator.fields) {
            const field = this.props.creator.fields[i];
            if (!this.state['creator-' + field]) return false;
        }
        return true;
    }
    
    onChangeCreatorField(field) {
        return (event) => {
            const stateChange = {};
            stateChange['creator-'+field] = event.target.value;
            this.setState(stateChange);
            this.checkCreatorFieldsFilled();
        }
    }
    
    onSubmitCreator(event) {
        event.preventDefault();
        const fieldValues = [];
        for(var i in this.props.creator.fields) {
            const field = this.props.creator.fields[i];
            fieldValues.push(this.state['creator-' + field]);
        }
        this.setState({
            creatorbuttonenabled: false,
            ...this.createEmptyCreatorFields(this.props)          
        });
        if(this.props.creator.button.action) this.props.creator.button.action(...fieldValues);
    }
    
    generateCreator() {
        if (!this.props.creator) return;
        
        const items = [];
        for (var i in this.props.creator.fields) {
            const field = this.props.creator.fields[i];
            const fieldHtml = <input class='adminuserstable-creator-inputtext' key={'adminusertable-creator-input-' + i} placeholder={field} value={this.state['creator-' + field]} onChange={this.onChangeCreatorField(field)}/>
            items.push(fieldHtml);
        }
        const buttonHtml = <input type='submit' disabled={!this.state.creatorbuttonenabled} class='adminuserstable-creator-inputbutton' key='adminusertable-creator-inputbutton' value={this.props.creator.button.label} />
        items.push(buttonHtml);
        return (
            <form class='adminuserstable-creator' onSubmit={this.onSubmitCreator}>
                {items}
            </form>
        );
    }
    
    onClickEntry(entry, action) {
        return (event) => {
            if (action) action(entry);
        }
    }
    
    generateDataSlots() {
        const dataLabelsHtml = [];
        for (var i in this.props.fieldlabels) {
            const label = this.props.fieldlabels[i];
            dataLabelsHtml.push(<p class='adminuserstable-data-fieldlabel' key={'adminuserstable-data-fieldlabel-' + i}>{label}</p>);
        }
        const dataHtml = [];
        for (var i in this.props.rawdata) {
            const entry = this.props.rawdata[i];
            const entryHtml = [];
            for (var j in this.props.fields) {
                const field = this.props.fields[j];
                const entryvalue = entry[field];
                entryHtml.push(<p class='adminuserstable-data-entry-field' key={'adminuserstable-data-entry-field-' + i + '-' + j}>{entryvalue}</p>)
            }
            for (var j in this.props.buttons) {
                const button = this.props.buttons[j];
                const buttonlabel = button.label;
                if (button.tolink) {
                    var tolink = button.tolink;
                    for (var k in this.props.fields) {
                        const field = this.props.fields[k];
                        tolink = tolink.replace('$' + field, entry[field]);
                    }
                    entryHtml.push(<Link class='adminuserstable-data-entry-button' key={'adminuserstable-data-entry-button-' + i + '-' + j} to={tolink} >{buttonlabel}</Link>)
                } else {
                    entryHtml.push(<button disabled={!button.action} class='adminuserstable-data-entry-button' key={'adminuserstable-data-entry-button-' + i + '-' + j} onClick={this.onClickEntry(entry, button.action)} >{buttonlabel}</button>);
                }
            }
            dataHtml.push(
                <div class='adminuserstable-data-entry' key={'aadminuserstable-data-entry' + i}>
                    {entryHtml}
                </div>
            );
        }
        return (
            <div class='adminuserstable-data'>
                <div class='adminuserstable-data-fieldlabels'>
                    {dataLabelsHtml}
                </div>
                <div class='adminuserstable-data-entries'>
                    {dataHtml}
                </div>
            </div>
        );
    }
    
    render() {
        const data = this.generateDataSlots();
        const creator = this.generateCreator();
        return (
            <div class='adminuserstable'>
                <p>{this.props.title}</p>
                {creator}
                {data}
            </div>
        );
    }
    
}