import React from 'react';

import './name.css';

export default class Name extends React.Component {

	render() {
		return (
			<div class='name'>
				{this.props.children}
			</div>
		);
	}

}