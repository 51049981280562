import React from 'react'

// import Header from '../components/header.js';
// import Footer from '../components/footer.js';

import Page from '../componentsv2/page.js';
import GuestAuth from '../componentsv2/auth/guestauth.js';
import RSVPConsole from '../componentsv2/webparts/rsvp/rsvpconsole.js'

export default class RSVPPage extends React.Component {

	render() {
		return (
			<Page rsvp>
			 	<GuestAuth>
                    {(props) => <RSVPConsole path={this.props.path} {...props}/>}
                </GuestAuth>
			</Page>
		);
	}

}