import React from 'react';

import './article.css';

export default class Article extends React.Component {

	constructor(props) {
		super(props);

		const paragraphs = this.generateParagraphs();

		this.state = {
			paragraphs
		};
	}

	generateParagraphs() {
		if (!this.props.paragraphs)
			return [];
		const allParagraphs = [];
		for (var i in this.props.paragraphs) {
			const paragraphText = this.props.paragraphs[i];
			const paragraph = (
				<p class='article-text' key={'article-text-' + i}>{paragraphText}</p>
			);
			allParagraphs.push(paragraph);
		}
		return allParagraphs;
	}

	render() {

		const leftImageStyle = {
			backgroundImage: 'url(' + this.props.leftImage + ')'
		};

		const rightImageStyle = {
			backgroundImage: 'url(' + this.props.rightImage + ')'
		}

		var offset = 0;
		if (this.props.leftImage)
			offset += 250;
		if (this.props.rightImage)
			offset += 250;
		const paragraphsStyle = {
			width: 'calc(100vw - ' + offset + 'px)',
			maxWidth: 'calc(1200px - ' + offset + 'px)'
		};

		return (
			<div class='article'>
				{this.props.leftImage && <div class='article-image' style={leftImageStyle} />}
				<div class='article-paragraphs' style={paragraphsStyle}>
					{this.state.paragraphs}	
				</div>
				{this.props.rightImage && <div class='article-image' style={rightImageStyle} />}
			</div>
		);
	}

}