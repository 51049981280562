import React from 'react';
import cookie from 'react-cookies';

import {signOutAdmin} from '../../../utils/authutils.js';
import {generateLinks} from '../../../utils/adminnavbarutils.js';

import './adminnavbar.css';

export default class AdminNavbar extends React.Component {

	render() {
		return (
			<div class='adminnavbar'>
				<div class='adminnavbar-path'>
					{generateLinks(this.props.path)}
				</div>
				<div class='adminnavbar-account'>
					<input class='adminnavbar-account-signout' type='submit' value='Sign Out' onClick={this.props.signOut}/> 
				</div>
			</div>
		);
	}

}