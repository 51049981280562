import React from 'react';

import './photoviewer.css';

export default class PhotoViewer extends React.Component {

	render() {
        console.log(this.props.imageUrl)
		return (
			<div class='photoviewer'>
                <div class='photoviewer-background' onClick={() => { if (this.props.closeViewer) this.props.closeViewer();}}/>
				<div class='photoviewer-navbar'>
                    <div class='photoviewer-navbar-group'>
                        <p class='photoviewer-navbar-cancel' onClick={() => { if (this.props.closeViewer) this.props.closeViewer();}}>✖</p>
                        <p class='photoviewer-navbar-title'>Lianne and David Rosales</p>
                    </div>
                    <div class='photoviewer-navbar-group'>
                        <p class='photoviewer-navbar-arrow' onClick={() => {if (this.props.prevImage) this.props.prevImage();}}>{'<'}</p>
                        <p class='photoviewer-navbar-arrow' onClick={() => {if (this.props.nextImage) this.props.nextImage();}}>{'>'}</p>
                    </div>
				</div>
				<img class='photoviewer-content-img' src={this.props.imageUrl} onClick={() => { if (this.props.nextImage) this.props.nextImage();}} />
			</div>
		);
	}

}