import React from 'react'

import Banner from './header/banner.js';
import Navbar from './header/navbar.js';

import './header.css';

export default class Header extends React.Component {

	render() {
		return (
			<div class='header'>
				<Banner />
				<Navbar {...this.props} />
			</div>
		);
	}

}