import React from 'react';

import './adminmanageuser.css';

export default class AdminManageUser extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            newdisplayname: '',
            newnickname: ''
        }
        
        this.onDisplayNameChange = this.onDisplayNameChange.bind(this);
        this.onNicknameChange = this.onNicknameChange.bind(this);
        
        this.handleSaveDisplayName = this.handleSaveDisplayName.bind(this);
        this.handleSaveNickname = this.handleSaveNickname.bind(this);
    }
    
    onDisplayNameChange(event) {
        this.setState({
            newdisplayname: event.target.value
        });
    }
    
    onNicknameChange(event) {
        this.setState({
            newnickname: event.target.value
        });
    }
    
    handleSaveDisplayName() {
        if (this.props.handleSaveDisplayName) 
            this.props.handleSaveDisplayName(this.props.guestgroup.groupusername, this.state.newdisplayname);
    }
    
    handleSaveNickname() {
        if (this.props.handleSaveNickname)
            this.props.handleSaveNickname(this.props.guestgroup.groupusername, this.state.newnickname);
    }
    
    render() {
        
        const previewNickname = !this.props.guestgroup ? '' : this.props.guestgroup.groupnickname ? this.props.guestgroup.groupnickname : this.props.guestgroup.groupdisplayname;
        
        return (
            <div class='adminmanageuser'>
                {this.props.guestgroup && <p class='adminmanageuser-title'>Managing the {this.props.guestgroup.groupusername} group</p>}
                {!this.props.guestgroup && <p class='adminmanageuser-title'>Group not found</p>}
                {this.props.guestgroup && <div class='adminmanageuser-content'>
                    <div class='adminmanageuser-content-overview'>
                        <p>Username: {this.props.guestgroup.groupusername}</p>
                        <p>Code: {this.props.guestgroup.groupcode}</p>
                        <p>Display name: {this.props.guestgroup.groupdisplayname}</p>
                        <p>Nickname: {this.props.guestgroup.groupnickname}</p>
                        <p>Rsvp for ceremony? {this.props.guestgroup.ceremonyrsvp == null ? 'Not yet rsvped' : this.props.guestgroup.ceremonyrsvp ? 'Yes' : 'No'}</p>
                        <p>Rsvp for reception? {this.props.guestgroup.receptionrsvp == null ? 'Not yet rsvped' : this.props.guestgroup.receptionrsvp ? 'Yes' : 'No'}</p>
                    </div>
                    <div class='adminmanageuser-content-update'>
                        <div class='adminmanageuser-content-update-section'>
                            <p class='adminmanageuser-content-update-section-title'>Change display name</p>
                            <div class='adminmanageuser-content-update-section-row'>
                                <input class='adminmanageuser-content-update-section-row-input' type='text' placeholder='new display name' value={this.state.newdisplayname} onChange={this.onDisplayNameChange} />
                                <button disabled={!this.state.newdisplayname} class='adminmanageuser-content-update-section-row-button' onClick={this.handleSaveDisplayName} >Save Display Name</button>
                            </div>
                        </div>
                        <div class='adminmanageuser-content-update-section'>
                            <p class='adminmanageuser-content-update-section-title'>Change nickname</p>
                            <div class='adminmanageuser-content-update-section-row'>
                                <input class='adminmanageuser-content-update-section-row-input' type='text' placeholder='new nickname' value={this.state.newnickname} onChange={this.onNicknameChange} />
                                <button class='adminmanageuser-content-update-section-row-button' onClick={this.handleSaveNickname} >{this.state.newnickname ? 'Save Nickname' : 'Clear Nickname'}</button>
                            </div>
                        </div>
                        <div class='adminmanageuser-content-update-preview'>
                            <p class='adminmanageuser-content-update-preview-title'>Preview</p>
                            <p class='adminmanageuser-content-update-preview-text'>Hi {previewNickname}! On the tabs above, you can checkout out our story, view and download photos, watch the wedding, and access our registry. On the day of the wedding (August 29th, 2020), we will be updating the Watch page with up to date information about the ceremony stream and Zoom reception. In order to access information about the Zoom reception, please complete your RSVP below. Completing it will allow us to plan the online reception in an organized manner. You are signed in as {this.props.guestgroup.groupdisplayname}.</p>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
    
}