import React from 'react';
import {Link} from 'react-router-dom';

import './navbar.css';

export default class Navbar extends React.Component {

//<Link class='navbar-link' to='/admin' id={this.props.admin ? 'selected' : ''}>Admin</Link>

	render() {
		return (
			<div class='navbar'>
				<div class='navbar-container'>
					<Link class='navbar-link' to='/' id={this.props.home ? 'selected' : ''}>Our Story</Link>
					<Link class='navbar-link' to='/photos' id={this.props.photos ? 'selected' : ''}>Photos</Link>
					<Link class='navbar-link' to='/watch' id={this.props.watch ? 'selected' : ''}>Watch</Link>
					<Link hidden={!this.props.rsvp} class='navbar-link' to='/rsvp' id={this.props.rsvp ? 'selected' : ''}>RSVP</Link>
					<Link hidden={!this.props.registry} class='navbar-link' to='/registry' id={this.props.registry ? 'selected' : ''}>Registry</Link>
					<Link hidden={!this.props.admin} class='navbar-link' to='/admin' id={this.props.admin ? 'selected' : ''}>Admin</Link>
				</div>
			</div>
		);
	}

}