import React from 'react';
import cookie from 'react-cookies';

import AuthCard from './authcard.js';

import {signIntoAdmin, signOutAdmin} from '../../utils/serverutils.js';
import {mapAdminStatusToString} from '../../utils/authutils.js';

import './adminauth.css';

export default class AdminAuth extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			adminusername: '',
			admintoken: ''
		};

		this.handleSignIn = this.handleSignIn.bind(this);
		this.handleSignOut = this.handleSignOut.bind(this);
	}

	componentDidMount() {
		this.setState({
			adminusername: cookie.load('adminusername'),
			admintoken: cookie.load('admintoken')
		});
	}

	handleSignIn(username, password) {
		this.setState({
			signInError: ''
		});

		signIntoAdmin(username, password).then(res => {
			this.setState({
				signInError: mapAdminStatusToString(res.status)
			});
			if (res.token) {
				// Successful sign in
				this.setState({
					adminusername: res.token.username,
					admintoken: res.token.token
				});
				cookie.save('adminusername', res.token.username, {path: '/'});
				cookie.save('admintoken', res.token.token, {path: '/'});
			}
		});
	}

	handleSignOut() {
		signOutAdmin(this.state.adminusername, this.state.admintoken).then(res => {
            // Clear the cookie regardless of server success
            cookie.remove('adminusername', {path:'/'});
            cookie.remove('admintoken', {path:'/'});
            this.setState({
                adminusername: '',
                admintoken: ''
            });
		})
	}

	render() {
		const childProps = {
			signOut: this.handleSignOut,
            adminusername: this.state.adminusername,
            admintoken: this.state.admintoken,
		}
		return (
			<div class='adminauth'>
				{!this.state.admintoken && <p class='adminauth-greeting'>Welcome to the Admin page.</p>}
				{!this.state.admintoken && <AuthCard tooltip='Enter your username and password' error={this.state.signInError} usernamePlaceholder='username' passwordPlaceholder='password' handleSignIn={this.handleSignIn}/>}
				{this.state.admintoken && this.props.children(childProps)}
			</div>
		);
	}

}