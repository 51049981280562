import React from 'react'

// import Header from '../components/header.js';
// import Footer from '../components/footer.js';

import Page from '../componentsv2/page.js';
import Article from '../componentsv2/webparts/article.js';
import Card from '../componentsv2/webparts/card.js';
import FlexRow from '../componentsv2/webparts/flexrow.js';

export default class RegistryPage extends React.Component {

	render() {
		return (
			<Page registry>
				<Article paragraphs={['Check out the different registry links below :)']} />
				<FlexRow>
					<Card title='Target' subtitle='www.target.com' url='https://www.target.com/gift-registry/giftgiver?registryId=28c786e3bd854d0d876f58b94e3f405b&type=WEDDING&ref=reg_home_friends_fam' thumbnail='https://1000logos.net/wp-content/uploads/2017/06/target-logo-transparent.png'/>
					<Card title='Amazon' subtitle='www.amazon.com' url='https://www.amazon.com/wedding/registry/1GIJJMRDV6H40?ref=wr_search_page_result_2' thumbnail='https://i.pinimg.com/originals/08/5f/d8/085fd8f7819dee3b716da73d3b2de61c.jpg'/>
                    <Card title='Wayfair' subtitle='www.wayfair.com' url='https://www.wayfair.com/registry/wedding/d0956ba3-a234-45bd-9ab3-c499585f8db1' thumbnail='https://storage.googleapis.com/wf-blogs-engineering-media/2018/12/logo-circle.png'  />
				</FlexRow>
                <Article paragraphs={['You can also contribute directly to one of our wedding funds!']} />
                <FlexRow>
                    <Card title='Honeymoon Fund' subtitle='www.paypal.com' url='https://paypal.me/pools/c/8rnt9E16tj' thumbnail='/images/registry/honeymoon.png' />
                    <Card title='Pet Fund' subtitle='www.paypal.com' url='https://paypal.me/pools/c/8rnXMlrmxt' thumbnail='/images/registry/pet.jpg' />
                    <Card title='Car Fund' subtitle='www.paypal.com' url='https://www.paypal.com/pools/c/8rzvaVj8lk' thumbnail='/images/registry/car.jpg' />
                </FlexRow>
			</Page>
		);
	}

}