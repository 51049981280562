import React from 'react';

import Name from './banner/name.js';

import './banner.css';

export default class Banner extends React.Component {

	//<img src='/images/banner.png' />

	render() {
        const now = new Date();
        const weddingDate = new Date(2020, 7, 29);
        const dayInMS = 1000*60*60*24;
        const days = Math.ceil((now.getTime() - weddingDate.getTime())/dayInMS) - 1;
        
		return (
			<div class='banner'>
				<div class='banner-names'>
					<Name>David</Name>
					<p class='banner-connector'>and</p>
					<Name>Lianne Rosales</Name>
				</div>
				<div class='banner-info'>
					<p class='banner-subtitle'>August 29th, 2020 - Andover, MA</p>
					<p class='banner-subtitle'>{days} days since "I do"</p>
				</div>
			</div>
		);
	}

}